const data = [
    {
        name:"Corporation Services",
        category:"Accounting Services",
        title:"Business Incorporation Services",
        img:"/serviceImages/corporationServices.jpg",
        text1:"Incorporation is just one part of the puzzle when it comes to starting a company. Being a good businessperson necessitates a constant search for new ways to reduce tax liabilities, cut costs, and boost income. Our Mountain View, CA CPA company, Access Capital Management Corp., provides new companies with incorporation and advisory services to help them get off to a good start.",
        text2:"Choosing the right structure for your new corporation would create a stable basis for lowering taxes and rising profits. We will describe your choices for corporate structure in terms you can understand, recommend the organization that will support your company, and align with your overall tax strategy, which could include S companies, C corporations, and LLCs. Following that, we will teach you how to write a solid business plan and how to better arrange the accounting and bookkeeping processes in order to secure funding. We will also provide additional accounting and reporting services so you can keep track of your finances. As your company expands, we will change your tax strategy to ensure that your tax obligations are minimized and your company’s value grows.",
        listTitle:"Incorporation and New Business Advisory",
        list:[
            {listItem:"Business formation – LLC, S Corp, C Corp"},
            {listItem:"Entity selection"},
            {listItem:"Help writing a business plan to secure financing"},
            {listItem:"Tax planning strategies for new business"},
            {listItem:"QuickBooks™ setup services"},
        ]
    }
]
export default data;
