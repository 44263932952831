import React, {useCallback, useRef, useState} from 'react';
import "./ThirdSection.scss"
import {Col, message, Row} from "antd";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";


const ThirdSection = () => {
    const [disableSubmit,setDisableSubmit] = useState(true);
    const form = useRef();
    const cancelCourse = () => {
        document.getElementById("create-course-form").reset();
    }
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_12brpj8', 'template_bkbzzhs', form.current, 'kPZ_HuRgWa0tP-0wE')
            .then((result) => {
                console.log(result.text);
                message.success("Message has send successfully");
                cancelCourse();
            }, (error) => {
                console.log(error.text);
            });
        window.grecaptcha.reset();
    };
    function onChange(value) {
        console.log("Captcha value:", value);
    }
    return (<div className="contact-third-section">
        <div className="my-container">
            <Row gutter={[30, 10]}>
                <Col className="map-side" span={24} md={12}>
                    <h3 className="title">Our Location</h3>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3169.996563575906!2d-122.09695458439606!3d37.38991354198061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb0c629d8e7e9%3A0x54d73725ff429730!2zMTA0OSBFbCBNb250ZSBBdmUsIE1vdW50YWluIFZpZXcsIENBIDk0MDQwLCDQodCo0JA!5e0!3m2!1sru!2s!4v1647267847167!5m2!1sru!2s"
                        width="100%" height="80%" allowFullScreen="" loading="lazy"></iframe>
                </Col>

                <Col className="form-side" span={24} md={12}>
                    <h3 className="title">Send your message</h3>
                    <form ref={form} id={"create-course-form"} onSubmit={sendEmail} className="form mt-4">
                        <Row>
                            <Col span={24} md={12} className="pe-2">
                                <input required type="text" name="first_name" className="input" placeholder="Name"/>
                            </Col>
                            <Col span={24} md={12} className="ps-2">
                                <input required type="email" name="email" className="input" placeholder="Email"/>
                            </Col>
                            <Col span={24}>
                                <input type="text" name="subject" className="input" placeholder="Subject"/>
                            </Col>
                            <Col span={24} className="mb-2">
                                <textarea type="text" name="message" className="input-textarea" placeholder="Message"/>
                                <br/>
                            </Col>
                            <ReCAPTCHA
                                sitekey="6LdRDQgfAAAAAH6aF55bnH6TQ6h3NZqR4mykKBHz"
                                onChange={useCallback(() => setDisableSubmit(false))}
                            />
                            <Col span={24} className="mt-2">
                                <button className={`submit ${disableSubmit === true ? "bg-secondary" : ""}`} disabled={disableSubmit}>Send an Inquiry</button>
                            </Col>
                        </Row>
                    </form>
                    <form>
                    </form>
                </Col>
            </Row>
        </div>
    </div>);
};

export default ThirdSection;
