const data = [
    {
        name:"Transportation",
        category:"Industries",
        title:"Transportation and Logistics",
        img:"/serviceImages/transportation.jpg",
        text1:"Access Capital Management Corp. is a Mountain View, CA CPA firm that helps trucking companies, over-the-road commercial drivers, and owner operators with their transportation accounting and tax needs. We apply proven tax and financial strategies that are a result of years of experience working with customers in the trucking industry. We offer outsourced accounting and bookkeeping, payroll, financial statements, tax planning and much more. With Access Capital Management Corp. support, you will improve internal management, avoid unnecessary expenses and save more of your earnings.",
        text2:"For carriers and the trucking industry, tax preparation is complex than the average tax return. By cooperating with us, you will be confident that you are taking advantage of all potential deductions. We are aware of any changes in the tax code and how they influences trucking industry. We are not limited to that you’re in consistence with all relevant assessment guidelines, however with Access Capital Management Corp. you won’t ever pay more than you owe.",
        listTitle:"Our accounting and CPA services for trucking business include:",
        list:[
            {listItem:"Outsourced accounting and bookkeeping"},
            {listItem:"Tax returns for commercial truckers"},
            {listItem:"Financial statement preparation"},
            {listItem:"Strategic tax planning"},
            {listItem:"State and local taxes"},
            {listItem:"Multi-state tax preparation"},
            {listItem:"Evaluation of internal controls"},
            {listItem:"Cash flow analysis"},
            {listItem:"Payroll services"},
            {listItem:"CFO and controller functions"},
            {listItem:"Help with IRS tax problems"},
        ]
    }
]
export default data;
