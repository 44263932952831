const data = [
    {
        name:"Tax Planning",
        category:"Tax Services",
        title:"Tax Planning Services",
        img:"/serviceImages/tax-planning.jpg",
        text1:"Effectively reducing taxes requires extensive tax planning that considers all possible deductions and tax code changes. We work closely with small business owners and individuals at Access Capital Management Corp. to build plans that take advantage of today’s benefits while still planning for future tax challenges. Some accountants only communicate with their customers during tax season, but we will keep in contact with you during the year to help you handle the tax workload and achieve optimal tax outcomes. When you become our client, you can rely on us to ensure that you never spend more than the law demands.",
        text2:"We aspire to be willing to answer questions and support you with making critical financial choices that can influence your taxes as your tax advisor. We know how to properly handle both state and federal tax commitments, and we will make adjustments to the schedule as new law becomes available. You can save money on taxes and keep some of your earnings if you follow our advice.",
        listTitle:"Tax Planning for Businesses and Individuals",
        list:[
            {listItem:"Strategic tax planning for small businesses"},
            {listItem:"Tax planning techniques for individuals"},
            {listItem:"Multi-state tax planning"},
            {listItem:"Alternative minimum tax planning"},
            {listItem:"Trust and estate planning"},
            {listItem:"Business succession planning"},
            {listItem:"Tax planning for nonprofit organizations"},
        ]
    }
]
export default data;
