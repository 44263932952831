const data = [
    {
        name:"Outsourced Accounting",
        category:"Accounting Services",
        title:"Outsourced Accounting and Bookkeeping",
        img:"/serviceImages/outsourced-accounting.jpg",
        text1:"Accounting and bookkeeping can put a strain on a small business owner’s limited time and money. Before important financial responsibilities start to fall through the cracks, consider the outsourced accounting and bookkeeping services offered through Access Capital Management Corp. For busy business owners, our dependable accounting services are the smart and cost-effective choice.",
        text2:"When we manage the accounting and bookkeeping, we take a realistic approach that requires a high degree of personal focus and sound advice. We will organise your taxes in a way that makes sense, is convenient, and makes tax planning faster and less costly at the end of the year. While we manage time-consuming activities including monthly closings, financial statements, and bank reconciliations, you can focus on running your company. Despite the fact that we will be doing the heavy lifting, we will ensure that you remain in charge and have constant access to the critical financial data you use to make critical financial decisions.",
        listTitle:"Locally Outsourced Accounting Services",
        list:[
            {listItem:"Outsourced accounting from a local CPA firm"},
            {listItem: "Bookkeeping services"},
            {listItem: "Services for payroll management"},
            {listItem: "Help with QuickBooks™ and file clean-up"},
            {listItem: "Financial statements"},
            {listItem: "Accounts payable and receivable"},
            {listItem: "Monthly and weekly reporting"},
            {listItem: "Cash balance tracking"},
            {listItem: "Balance sheets"},
            {listItem: "Closings every month"},
            {listItem: "General ledger maintenance"},
            {listItem: "Reconciliations with credit card"},
        ]
    }
]
export default data;
