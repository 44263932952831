import React from 'react';
import "./MainPage.scss";
import Header from "../../components/header/Header";
import FirstSection from "./sections/FirstSection";
import SecondSection from "./sections/SecondSection";
import ThirdSection from "./sections/ThirdSection";
import FourthSection from "./sections/FourthSection";
import FifthSection from "./sections/FifthSection";
import Footer from "../../components/footer/Footer";
import SixthSection from "./sections/SixthSection";

const MainPage = () => {
    return (
        <div className="main_page" >
            {/*<Header/>*/}
            <FirstSection/>
            <SecondSection/>
            <ThirdSection/>
            <FourthSection/>
            <FifthSection/>
            <SixthSection/>
            {/*<Footer/>*/}
        </div>
    );
};

export default MainPage;
