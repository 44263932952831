import React from 'react';
import "./ThirdSection.scss";
import {Col, Row} from "antd";
import Fade from "react-reveal";

const data = [
    {
        icon: "/output-onlinepngtools2.png",
        title: "Bookkeeping service",
        subtitle: "Bookkeeping is an exhausting and time-consuming process. Our company can handle your bookkeeping on a monthly basis to guarantee everything is structured and consistent."
    },
    {
        icon: "/CFO.png",
        title: "Outsourced CFO Services",
        subtitle: "Local companies will benefit from the experience of a CFO without adding another person on their payroll by using our outsourced controller and CFO services"
    },
    {
        icon: "/quickbook.png",
        title: "QuickBooks Accounting",
        subtitle: "We make it easy for new business owners to get started with QuickBooks. We will assist you with QuickBooks setup and offer extra assistance whenever you have questions"
    },
    {
        icon: "/Payroll.png",
        title: "Payroll Management Services",
        subtitle: "Small business owners also struggle to find the time and money to remain on top of payroll. Our CPA firm takes the guesswork out of paysheet processing"
    },
    {
        icon: "/tax.png",
        title: "Tax Preparation Services",
        subtitle: "We assist taxpayers to keep more of their money. Allow  expert, such as Access Capital Management Corp. to prepare your taxes and guarantee you never lose any deductions"
    },
    {
        icon: "/IRC.png",
        title: "IRS Representation",
        subtitle: "Getting competent representation through an audit is vital, and our experience with tax officials helps us to assist clients in communicating with federal and state departments"
    },

]
const ThirdSection = () => {
    return (<div className="third_section">
        <Row>
            <Col span={24} xl={9}>
                <div className="left_side">
                    <div className="text_cont">
                        <h1 className="title">We Make a Complex World Simple</h1>
                        <div className="white_dash mx-auto"/>
                    </div>
                </div>
            </Col>
            <Col span={24} xl={15}>
                <div className="right_side">
                    <div className="main_title">
                        <h1 className="title">Our <span style={{color:"var(--secondary)"}}>Services</span></h1>
                        <div className="white_dash mx-auto my-0 mb-5"/>
                    </div>
                    <div className="cards">
                        <Row>
                            {data.map((v, i) => {
                                return (
                                    <Col span={24} md={12} key={i} className="d-flex h-100">
                        <Fade bottom cascade >
                                        <div className="">
                                            <img className="icon" src={v.icon} alt=""/>
                                            {/*<FontAwesomeIcon className="icon" icon={v.icon}/>*/}
                                        </div>
                                        <div className="card_text">
                                            <p className="title">{v.title}</p>
                                            <p className="subtitle">{v.subtitle}</p>
                                        </div>
                        </Fade>
                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                </div>
            </Col>
        </Row>
    </div>);
};

export default ThirdSection;
