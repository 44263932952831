import React from 'react';
import {Col, Row} from "antd";
import "./Footer.scss";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot, faPhone, faEnvelope} from "@fortawesome/free-solid-svg-icons"
import {FacebookSvgF, InstagramSvg, TwitterSvg, YoutubeSvg} from "../../assets/social-icons/socialIcons";

const linkNames = [
  {name: "Home Page", linkPath: "/"},
  {name: "About Us", linkPath: "/about"},
  {name: "Accounting Services", linkPath: "/outsourcedAccounting"},
  {name: "Out Sourced CFO Services", linkPath: "/outsourcedCfoServices"},
  {name: "Tax Preparation Services", linkPath: "/taxPreparationServices"},
  {name: "Tax Planning", linkPath: "/taxPlanning"},
  {name: "Hotels and Restaurants", linkPath: "/hotelsAndRestaurants"},
  {name: "Transportation", linkPath: "/transportation"},
  {name: "Contact Us", linkPath: "/contact"}]

const Footer = () => {
  return (
    <>
      <footer className="footer-wrapper">
        <div className="my-container ">
          <Row>
            <Col className="first-col" span={24} md={7}>
              <div className="logo-wrapper">
                <Link to="/">
                  <img src="/logo2.png" alt="ReadyAcc"/>
                </Link>
              </div>
              <p className="">Access Capital Management Corp. works with
                companies and corporations in a number of
                markets to offer a wide range of services.</p>
              <ul className="social-links-list">
                <li className="item">
                  <a href="https://www.instagram.com/" target="_blank">
                    <InstagramSvg/>
                  </a>
                </li>
                <li className="item">
                  <a href="https://www.youtube.com/" target="_blank">
                    <YoutubeSvg/>
                  </a>
                </li>
                <li className="item">
                  <a href="https://twitter.com/" target="_blank">
                    <TwitterSvg/>
                  </a>
                </li>
                <li className="item">
                  <a href="https://www.facebook.com/" target="_blank">
                    <FacebookSvgF/>
                  </a>
                </li>
              </ul>
            </Col>

            <Col className="second-col" span={24} md={6}>
              <p>
                We strive to meet each client's particular needs
                in terms of long-term planning and achieving
                their targets in a constantly evolving financial
                and regulatory environment.
              </p>
            </Col>

            <Col className="third-col" span={24} md={5}>
              <h3 className="title">Links</h3>
              <ul className="list">
                {linkNames.map(linkObj => {
                  return (
                    <li className="item" key={linkObj.name}>
                      <Link to={linkObj.linkPath}>{linkObj.name}</Link>
                    </li>
                  )
                })}
              </ul>
            </Col>

            <Col className="fourth-col" span={24} md={6}>
              <h3 className="title">Contact</h3>
              <ul className="list">
                <li className="item">
                  <div className="svgWrapper">
                    <FontAwesomeIcon icon={faLocationDot}/>
                  </div>
                  <div>1049 El Monte Ave , Ste C #797<br/>Mountain View, <br/> CA 94040</div>
                </li>
                <li className="item">
                  <div className="svgWrapper">
                    <FontAwesomeIcon icon={faPhone}/>
                  </div>
                  <a className="link-tel" href="tel: +1 (408) 856-2585">
                    +1 (408) 856-2585
                  </a>
                </li>
                <li className="item">
                  <div className="svgWrapper">
                    <FontAwesomeIcon icon={faEnvelope}/>
                  </div>
                  <div>
                    <a href="mailto:sam@readyacc.com">
                      sam@readyacc.com
                    </a>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </footer>
      <Row className="footer-bottom-row">
        <Col span={24}>
          <p>© 2022 ReadyAcc All rights reserved. Powered by
            <a target="_blank" href="http://revotech.uz/"> RevoTech</a>
          </p>
        </Col>
      </Row>
    </>
  );
};

export default Footer;
