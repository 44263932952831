import outsourcedCfoServices from "./outsourcedCfoServices";
import corporationServices from "./corporationServices";
import quickBooksAccounting from "./quickBooksAccounting";
import outsourcedAccounting from "./outsourcedAccounting";
import payrollManagementService from "./payrollManagementService";
import taxPlanning from "./taxPlanning";
import taxPreparationServices from "./taxPreparationServices";
import ircRepresentation from "./ircRepresentation";
import hotelsAndRestaurants from "./hotelsAndRestaurants";
import transportation from "./transportation";
import retailAndEcommerce from "./retailAndEcommerce";
import lawFirms from "./lawFirms";
import startUps from "./startUps";
import nonprofits from "./nonprofits";

const data = {
    "outsourcedAccounting": outsourcedAccounting,
    "outsourcedCfoServices": outsourcedCfoServices,
    "corporationServices": corporationServices,
    "quickBooksAccounting": quickBooksAccounting,
    "payrollManagementService": payrollManagementService,
    "taxPlanning": taxPlanning,
    "taxPreparationServices" : taxPreparationServices,
    "ircRepresentation" : ircRepresentation,
    "hotelsAndRestaurants" : hotelsAndRestaurants,
    "transportation" : transportation,
    "retailAndEcommerce" : retailAndEcommerce,
    "lawFirms" : lawFirms,
    "startUps" : startUps,
    "nonprofits" : nonprofits
}
export default data;