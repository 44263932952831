import React from 'react';
import "./ServicesFirstSection.scss";
import {Breadcrumb} from "antd";
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem";
import {Link} from "react-router-dom";

const ServicesFirstSection = ({data}) => {
    console.log(data)
    return (
        <div className="services_first_section">
            {data.map((v, i) => {
                return (
                    <div key={i}>
                        <h1>{v.name}</h1>
                        <Breadcrumb className="breadcrumb">
                            <BreadcrumbItem className="breadcrumb_items"><Link to="/">home</Link></BreadcrumbItem>
                            <BreadcrumbItem className="breadcrumb_items">{v.category}</BreadcrumbItem>
                            <BreadcrumbItem className="breadcrumb_items">{v.name}</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                )
            })}
        </div>
    );
};

export default ServicesFirstSection;
