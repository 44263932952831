import React from 'react';
import "./SecondSection.scss";
import Fade from "react-reveal";


const SecondSection = () => {
  return (
    <div className="contact-second-section">
      <div className="my-container">
        <Fade bottom cascade>
        <h2 className="title">
          We are ready to help you
        </h2>
        <p className="text">Please provide the following details along with
          your message so we may appropriately assist
          you. We will protect your personal information.
          We will get in touch today or next business day.</p>
        </Fade>
      </div>
    </div>
  );
};

export default SecondSection;
