import React from 'react';
import "./SixthSection.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {Col, Row} from "antd";
import {Link} from "react-router-dom";

const SixthSection = () => {
    return (
        <div className="sixth_section">
            <div className="my-container">
            <Row className="get_in_touch">
                <Col span={24} lg={16} className="git_text">
                    <p className="git_title">Looking for a custom solution ?</p>
                    <p className="git_subtitle">Contact us to book an one-on-one video
                      conference so we can listen to your needs and
                      provide the best consultation.</p>
                </Col>
                <Col span={24} lg={8} className="button_cont">
                    <Link className="button" to="/contact"><FontAwesomeIcon icon={faEnvelope}/>&ensp;&ensp;Get In Touch</Link>
                </Col>
            </Row>
            </div>
        </div>
    );
};

export default SixthSection;
