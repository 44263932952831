import React, {useEffect, useState} from 'react';
import "./Header.scss";
import {Link, NavLink} from "react-router-dom";
import AccordionNav from "./AccordionNav";

const accounting = [
    {
        name: "Outsourced Accounting",
        link: "outsourcedAccounting"
    },
    {
        name: "Outsourced CFO Services",
        link: "outsourcedCfoServices"
    },
    {
        name: "Corporation Services",
        link: "corporationServices"
    },
    {
        name: "QuickBooks Accounting",
        link: "quickBooksAccounting"
    },
    {
        name: "payroll Management Service",
        link: "payrollManagementService"
    }
]

const taxServices = [
    {
        name: "tax Planning",
        link: "taxPlanning"
    },
    {
        name: "tax Preparation Services",
        link: "taxPreparationServices"
    },
    {
        name: "IRC Representation",
        link: "ircRepresentation"
    }
]

const industries = [
    {
        name: "hotels And Restaurants",
        link: "hotelsAndRestaurants"
    },
    {
        name: "transportation",
        link: "transportation"
    },
    {
        name: "retail And E-commerce",
        link: "retailAndEcommerce"
    },
    {
        name: "law Firms",
        link: "lawFirms"
    },
    {
        name: "start-Ups",
        link: "startUps"
    },
    {
        name: "nonprofits",
        link: "nonprofits"
    }
]

const Header = ({isBurgerOpen, setIsBurgerOpen}) => {
    const [header, setHeader] = useState("header");

    const listenScrollEvent = (event) => {
        if (window.scrollY < 30) {
            return setHeader("header")
        } else if (window.scrollY > 28) {
            return setHeader("header2")
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        return () => window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    const linkNotWorking = (e) => {
        e.preventDefault()
    }

    return (
        <div className={`header_wrapper ${header} ${isBurgerOpen ? "burgerOpenNav" : ""}`}>
            <div className="header_contacts">
                <div className="my-container">
                <div className="header_contacts-container">
                    <div className="d-flex">
                        <p>Call us: <a href="tel:+1(408)856-2585">+1 (408) 856-2585</a> &nbsp;</p>
                        <p><a href="mailto:sam@readyacc.com">Email: sam@readyacc.com</a></p>
                    </div>
                    <div className="d-flex">
                    </div>
                </div>
                </div>
            </div>
            <div className="my-container">
                <div className="header_cont">
                    <div className="logo">
                        <Link to="/" onClick={() => setIsBurgerOpen(false)}>
                            <img src={header !== "header" ? "/logo.png" : "/logo2.png"} alt=""/>
                        </Link>
                    </div>

                    <div className="navbar">

                        <div
                            className={`burger ${isBurgerOpen ? "open" : ""}`}
                            onClick={() => setIsBurgerOpen(p => !p)}
                        >
                            <span></span>
                        </div>

                        <ul>
                            <li><NavLink className="navbar__link" to="/">Home</NavLink></li>
                            <li>
                                <Link className="navbar__link" to="" onClick={linkNotWorking}>
                                    Accounting Services
                                </Link>
                                <div className="dropdown">
                                    <ul className="list">
                                        {accounting.map(v => {
                                            return <li key={v.name}>
                                                <Link to={`/${v.link}`}>{v.name}</Link>
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <Link className="navbar__link" to="" onClick={linkNotWorking}>Tax Services</Link>
                                <div className="dropdown">
                                    <ul className="list">
                                        {taxServices.map(v => {
                                            return <li key={v.name}>
                                                <Link to={`/${v.link}`}>{v.name}</Link>
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <Link className="navbar__link" to="" onClick={linkNotWorking}>Industries</Link>
                                <div className="dropdown">
                                    <ul className="list">
                                        {industries.map(v => {
                                            return <li key={v.name}>
                                                <Link to={`/${v.link}`}>{v.name}</Link>
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            </li>
                            <li><NavLink className="navbar__link" to="/about">About Us</NavLink></li>
                            <li><NavLink className="navbar__link appointment" to="/contact"> MAKE AN INQUIRY</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <AccordionNav linkNotWorking={linkNotWorking} isBurgerOpen={isBurgerOpen}
                          setIsBurgerOpen={setIsBurgerOpen}/>
        </div>
    );
};

export default Header;
