import React from 'react';
import "./FifthSection.scss";
import {Parallax} from "react-parallax";
import MySwiper from "./subSectoins/MySwiper";

const FifthSection = () => {
    return (
        <div className="fifth_section">
            <Parallax bgImage={"/testm.jpg"} blur={{min: -1, max: 0}}>
                <div className="parallax_bgc">
                    <div className="my-container">
                    <div className="">
                        <h1 className="title">What <span style={{color:"var(--secondary)"}}>Customers Say</span></h1>
                        <div className="orange_dash"/>
                    </div>
                    <MySwiper/>
                    </div>
                </div>
            </Parallax>
        </div>)
};

export default FifthSection;
