import React from 'react';
import "./SecondSection.scss";
import {Col, Row} from "antd";
import {Link} from "react-router-dom";
import Fade from "react-reveal";

const data = [
  {
    img: "/budget2.png",
    title: "ACCOUNTING",
    subtitle: "Our accounting services support business \n" +
      "owners with controlling their cashflows and thus \n" +
      "creating an opportunity for them to focus more \n" +
      "on their core businesses.",
    class: "first"
  }, {
    img: "/output-onlinepngtools.png",
    title: "BOOKKEEPING",
    subtitle: "On the off chance your bookkeeping system is \n" +
      "overwhelmed and making duplicate results or it \n" +
      "just needs some organizational assistance from \n" +
      "an expert, consider about an accounting \n" +
      "cleanup.",
    class: "second"
  }, {
    img: "/money-bag.png",
    title: "TAX OPTIMIZATION",
    subtitle: "We make sure that we create a true value for \n" +
      "your family and business by giving you the best \n" +
      "tax advice and assistance.",
    class: "third"
  },]

const SecondSection = () => {
  return (<div className="second_section">
    <div className="my-container">
      <div className="cards">
        <Fade bottom cascade>
        <Row>
          {data.map((v, i) => {
            return (<Col className="card_cont mb-md-4" span={24} md={12} lg={8} key={v.title}>
              <div className={`cardd text-center ${v.class}`}>
                <img src={v.img} alt=""/>
                {/*<FontAwesomeIcon className="icon" icon={faEarth}/>*/}
                <div className="text">
                  <p className="title">{v.title}</p>
                  <p className="subtitle">{v.subtitle}</p>
                </div>
              </div>
            </Col>)
          })}
        </Row>
        </Fade>
      </div>
      <div className="second_section_about">
        <Row>
          <Col span={24} lg={8} className="title">
          <Fade bottom>
            <h1 style={{color: "var(--primary)"}}>About<span style={{color: "var(--secondary"}}> Us</span></h1>
            <div className="orange_dash"/>
          </Fade>
          </Col>
          <Col span={24} lg={16} className="subtitle">
            <Fade right>
            <p>Access Capital Management Corp. is a full-service accounting company that concentrates
              specifically on customer needs. We offer
              accounting, payroll, tax preparation for
              businesses and individuals, tax planning,
              compilation and review, IRS representation, IRS
              tax resolutions and business advisory services
              for all business sizes. We are open, adaptable
              and personal.
            </p>
            <Link className="learn-more-btn" to="/about">Learn more</Link>
            </Fade>
          </Col>
        </Row>
      </div>
    </div>
  </div>);
};

export default SecondSection;
