const data = [
    {
        name: "Hotels and Restaurants",
        category:"Industries",
        title:"Hotels and Restaurants",
        img:"/serviceImages/hotels.jpg",
        text1:"In today’s dynamic market, the restaurant and hospitality industry strives to improve business and reducing expenses by maintaining the highest levels of quality and customer satisfaction. Nevertheless, as a restaurant or franchise owner, they do not have enough time to manage employees, process regular payment to vendors, manage everyday procedures and most vitally handle accurate hotel and restaurant accounting.",
        text2:"Access Capital Management Corp. provides hotel & restaurant owners with affordable hotel & restaurant accounting services.",
        text3:"Our company makes a difference to the normal restaurant and hotel bookkeeping services by scrutinizing the key aspects of the business and providing integrated accounting solutions. We have broad background in dealing with the franchise driven hotel or possessed by a single-headed proprietorship with the most profitable solutions.",
        listTitle:"Provided accountings services for Hotels & Restaurant businesses",
        list:[
            {listItem:"Bookkeeping"},
            {listItem:"Accounts Payable"},
            {listItem:"Accounts Receivable"},
            {listItem:"Management Reporting"},
            {listItem:"Bank Reconciliations"},
            {listItem:"Payroll Processing"},
        ]
    }
]
export default data;
