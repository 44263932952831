import React from 'react';
import "./Services.scss";
import {useParams} from "react-router-dom";
import datas from "./sections/data";
import ServicesFirstSection from "./sections/ServicesFirstSection";
import ServicesSecondSection from "./sections/ServicesSecondSection";

const Services = () => {
    const {services} = useParams()
    const data = datas[services]
    console.log(data)
    return (<>
        <div className="services_wrapper">
            <ServicesFirstSection data={data}/>
            <ServicesSecondSection data={data}/>
        </div>
    </>);
};

export default Services;
