import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination} from "swiper";
import "./MySwiper.scss"

const data = [
    {
        img:"slide1.jpg",
        description:"I would highly recommend professionals at Access Capital Management Corp. They areprompt, demonstrate a high level of expertise, and look out for my best interest!",
        name:"Kerri Johnson",
        profession:"Human Resources Manager"
    },
    {
        img:"slide2.jpg",
        description:"My paperwork was chaos. Now I have no stress about end of year returns which makes my life so much easier so I can concentrate my energy on the more creative aspects of my work.",
        name:"William Roberts",
        profession:"Teacher"
    },
    {
        img:"slide3.jpg",
        description:"I thought I had fallen through the cracks of the government rescue scheme and was about to lose everything. I contacted TaxAssist Accountants who provided me with invaluable advice and assistance.",
        name:"Linda Garcia",
        profession:"Pharmacist"
    }
]

const MySwiper = () => {
    return (
        <div className="mySwiper">
            <div className="swiper_cont">
                <Swiper
                    slidesPerView={2}
                    spaceBetween={30}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination]}
                    breakpoints={{
                        // when window width is >= 640px
                        300: {
                            slidesPerView: 1,
                            spaceBetween: 20
                        },
                        800: {
                            slidesPerView: 2,
                            spaceBetween: 20
                        },
                    }}
                    className="mySwiper"
                >
                    {data.map((v,i) => {
                        return(
                            <SwiperSlide key={v.name}>
                                <div className="my-slide-item d-flex mb-4">
                                    <div className="">
                                        <img className="avatar" style={{width:"100px", height:"100px"}} src={v.img} alt=""/>
                                    </div>
                                    <div className="text">
                                        <p className="description">{v.description}</p>
                                        <p className="name">{v.name}</p>
                                        <p className="profession">{v.profession}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </div>
    );
};

export default MySwiper;
