const data = [
    {
        name:"IRC Representation",
        category:"Tax Services",
        title:"IRS Representation",
        img:"/serviceImages/irc.jpg",
        text1:"Are you having issues with the Internal Revenue Service (IRS)? Access Capital Management Corp. will assist you in resolving your tax issues and putting them behind you for good. When you want to consult with our tax professionals in Mountain View, CA we will personally negotiate with the IRS on your behalf. We know how to deal with the IRS on everything from back taxes and IRS audit representation to tax liens, tax levies, and wage garnishment.",
        text2:"We will present you with tax relief solutions that are the right choice for your case until we have all of the information. We will help you set up a repayment plan or a deal of mediation to pay down your tax debt, as well as applying for penalty abatement, innocent partner relief, or currently not collectible status if needed. Whatever tax issues you are facing, we will patiently answer all of your questions and discuss any problems you might have so that you can make an informed decision that will permanently fix the problem.",
        listTitle:"Access Capital Management Corp. Tax Specialist",
        list:[
            {listItem:"Tax solutions for businesses"},
            {listItem:"Tax relief for individual taxpayers"},
            {listItem:"Representation during an IRS audit"},
            {listItem:"Reducing tax penalties"},
            {listItem:"Back taxes and late taxes to be filed"},
            {listItem:"Assistance removing tax liens and levies"},
            {listItem:"Ending wage garnishment"},
            {listItem:"Filing an Offer in Compromise or Installment Agreement"},
        ]
    }
]
export default data;
