const data = [
    {
        name:"Outsourced CFO Services",
        category:"Accounting Services",
        title:"Controllership and Outsourced CFO services",
        img:"/serviceImages/outsourced-cfo.jpg",
        text1:"Emerging companies need the financial skills of a CFO or controller, but they are not necessarily willing to hire one on a full-time basis. Access Capital Management Corp. is dedicated to assisting small companies with their exponential growth by offering outsourced CFO and controller services. For companies who need to boost accounting efficiencies, optimize tax policies, and overcome financial difficulties in order to keep going forward, our assistance is the most cost-effective option. When we step in as your part-time CFO, we will work with you to implement strong accounting and tax processes that will help you streamline your finances and boost your company’s efficiency.",
        text2:"Our CPA company in Mountain View, CA will provide you with targeted business strategies that can help you overcome challenges and achieve your financial goals. We will look at every aspect of the company and come up with an actionable strategy focused on solid a financial analysis that will improve controls, boost competitiveness, and mitigate risk.",
        listTitle:"Part-Time CFO Services and Controller",
        list:[
            {listItem:"Interim CFO or Controller"},
            {listItem:"Part-time CFO services"},
            {listItem:"Business improvement strategies"},
            {listItem:"Strategic business planning"},
            {listItem:"Financial analysis and reporting"},
            {listItem:"Managing cash balance"},
            {listItem:"Tax planning to maximize profits"},
            {listItem:"Operations review"},
            {listItem:"Training for in-house accountants and bookkeepers"},
            {listItem:"Risk management"},
            {listItem:"Due diligence"},
        ]
    }
]
export default data;
