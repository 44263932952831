const data = [
    {
        name:"Start-Ups",
        category:"Industries",
        title:"Accounting Services for Start-ups",
        img:"/serviceImages/startups.jpg",
        text1:"Start-ups encounter an uncommon set of challenges. To overcome those challenges, a start-up in its initial stages has various requirements than a more developed one. Wherever your company falls on this scale, Access Capital Management Corp. will assist you in identifying and implementing the steps required to build a solid financial infrastructure.",
        text2:"We’ve worked with a number of startups on different areas of operations. We provide financial and IT solutions that can be applied to your startup’s certain requirements. Organize your startup for the next level of success by using modern technological resources, real market insights, and robust service offerings such as accounting and bookkeeping.",
        listTitle:"Our accounting and CPA services for trucking business include:",
        list:[
            {listItem:"Outsourced accounting and bookkeeping"},
            {listItem:"Tax returns for commercial truckers"},
            {listItem:"Financial statement preparation"},
            {listItem:"Strategic tax planning"},
            {listItem:"State and local taxes"},
            {listItem:"Multi-state tax preparation"},
            {listItem:"Evaluation of internal controls"},
            {listItem:"Cash flow analysis"},
            {listItem:"Payroll services"},
            {listItem:"CFO and controller functions"},
            {listItem:"Help with IRS tax problems"},
        ]
    }
]
export default data;
