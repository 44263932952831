const data = [
    {
        name: "Non Profits",
        category:"Industries",
        title:"Nonprofit Accounting Services",
        img:"/serviceImages/nonprofit.jpg",
        text1:"Nonprofit organizations are a vital part of every community but complicated administrative requirements can deplete your resources and divert your attention away from your task. Access Capital Management Corp. has experience in nonprofit accounting and will show you how to effectively manage your donations so that you can do the most good.",
        text2:"Our company can establish and enhance your accounting and bookkeeping procedures so you have the enough resources to achieve your goals. We will make your financial statements clearer so you can easily monitor where your money is going and where you can lower or eliminate expenses. We will also make sure your charity status is maintained for tax purposes and will prepare and file your Form 990 tax return at the end of the year. With Access Capital Management Corp., you will have more time and financial resource on hand to make significant impact for your community.",
        listTitle:"Tax and Accounting Services for Nonprofits include:",
        list:[
            {listItem:"Accounting services for nonprofit organizations"},
            {listItem:"Cash flow management"},
            {listItem:"Compilations of financial statements"},
            {listItem:"Internal controls analysis"},
            {listItem:"Outsourced bookkeeping services"},
            {listItem:"Payroll Services"},
            {listItem:"Tax planning and compliance"},
            {listItem:"Assistance filing for tax exempt status"},
            {listItem:"Preparation of Form 990 tax returns"},
        ]
    }
]
export default data;
