import React from 'react';
import "./ThirdSection.scss"
import {Row, Col} from "antd";
import Fade from "react-reveal";

const ThirdSection = () => {
  return (
    <div className="about-third-section">
      <div className="my-container">
        <Row gutter={ [30, 60] }>
          <Col span={24} md={12}>
            <Fade bottom cascade>
            <div className="item">
              <div className="img-wrapper">
                <img src="./pixlr-bg-result.png" alt="image"/>
              </div>
              <div className="item-content">
                <h3 className="title">Our Vision</h3>
                <p className="text">
                  To be recognized globally as the ultimate
                  accounting and taxing company for providing
                  comprehensive financial and professional
                  services to individuals and businesses alike.
                </p>
              </div>
            </div>
            </Fade>
          </Col>
          <Col span={24} md={12}>
            <Fade bottom cascade>
              <div className="item">
              <div className="img-wrapper">
                <img src="./20943475.png" alt="image"/>
              </div>
              <div className="item-content">
                <h3 className="title">Our Mission</h3>
                <p className="text">
                  To provide exceptional financial and professional
                  services while maintaining the highest levels of
                  integrity and professionalism. To meet the current
                  and future needs of our clients and help them to
                  cultivate long-term success. To respect our staff
                  and those we serve as professionals and
                  individuals.
                </p>
              </div>
            </div>
            </Fade>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ThirdSection;
