import React, {useEffect} from 'react';
import "./FourthSection.scss";
import {Parallax} from "react-parallax";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartLine} from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import {Col, Row} from "antd";
import VisibilitySensor from "react-visibility-sensor"

const data = [
    {
        icon: "/yearsOfExp.png",
        number: "10",
        description: "Years of experience",
        suffix:"+"
    },
    {
        icon: "/happyClients.png",
        number: "500",
        description: "Happy Clients",
        suffix:"+"
    },
    {
        icon: "/professionAdv.png",
        number: "40",
        description: "Profesional Advisors",
        suffix:"+"
    },
    {
        icon: "/managementA.png",
        number: "10",
        description: "Management Assets",
        suffix:"+"
    },

]

const test = [12, 1]

const FourthSection = () => {
    const [focus, setFocus] = React.useState(false);
    return (
        <div className="fourth_section">
            <Parallax bgImage={"/counter.jpg"} blur={{min: -1, max: 0}} strength={300}>
                <div className="parallax_bgc">
                    <div className="my-container2">
                        <h1 >We are <span style={{color:"var(--secondary)"}}>In Numbers</span></h1>
                        <div className="white_dash mx-auto"/>
                        <Row className="count_up">
                            {data.map((v, i) => {
                                return (
                                  <Col className="count_cont" key={i}>
                                      <div>
                                          <img src={v.icon} className="icon" alt=""/>
                                          {/*<FontAwesomeIcon className="icon" icon={v.icon}/>*/}
                                      </div>
                                      <div className="count_text">
                                          <p className="number"><CountUp start={focus ? 0 : null} end={v.number} duration={2} suffix={v.suffix}>{({ countUpRef, start }) => (
                                              <VisibilitySensor onChange={(isVisible) => {
                                                  if (isVisible) { setFocus(true); }
                                              }} delayedCall>
                                                  <span ref={countUpRef} />
                                              </VisibilitySensor>
                                          )}</CountUp></p>
                                          <p className="description">{v.description}</p>
                                      </div>
                                  </Col>
                                )
                            })}
                        </Row>
                    </div>
                </div>
            </Parallax>
        </div>
    );
};

export default FourthSection;
