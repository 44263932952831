import React from 'react';
import "./FirstSection.scss"
import {Link} from "react-router-dom";
import Fade from "react-reveal";

const FirstSection = () => {
    return (
        <div className="first_section">
          <div className="bgi"/>
            <div className="my-container first_section-container">
                <Fade left cascade>
              <div className="text">
                  <h1>Ultimate Accounting and <br/> Taxation Solutions</h1>
                  <p className="subtitle">We handle your accounting and taxation needs <br/> so you can fully focus on your core business.</p>
              </div>
                <div className="">
                <Link className="button" to="/about">Learn more</Link>
                </div>
                </Fade>
            </div>
        </div>
    );
};

export default FirstSection;
