const data = [
    {
        name:"Quick Books Accounting",
        category:"Accounting Services",
        title:"Accounting Assistance and Encouragement for Quickbooks",
        img:"/serviceImages/quickbooks.jpg",
        text1:"In the form of financial statements, analyses, and diagrams, QuickBooks can provide valuable and timely material. However, you can only get this information if you buy the correct product and then install, setup, and use it properly. We do not just help you how to use QuickBooks; we show you how to use it more reliably and effectively.",
        text2:"We provide new QuickBooks users with everything from the EasyStep Interview to Preferences, Lists, Customers, Vendors, Employees, Banking, and Reports. An inadequate setup of the program often creates issues for the users and disables to generate and track important data. To prevent from any such potential problems, we assist the users with improving their current setup to manage their businesses more successfully.",
        listTitle:"QuickBooks Consulting",
        list:[
            {listItem:"Selecting applicable QuickBooks for your business type"},
            {listItem:"Setting up QuickBooks software"},
            {listItem:"Data file analyzing and clean-up"},
            {listItem:"Making templates for invoices"},
            {listItem:"Determining and troubleshooting problems and consulting"},
        ]
    }
]
export default data;
