import React from 'react';
import "./SecondSection.scss"
import {Row, Col} from "antd";
import Fade from "react-reveal";

const SecondSection = () => {
  return (
    <div className="about-second-section">
      <div className="my-container">
        <Row gutter={[30, 10]}>
          <Col span={0} md={12}></Col>
          <Col span={24} md={12}>
            <Fade right>
            <h3 style={{textAlign: "center", marginBottom: "25px", fontWeight: 700}} className="title">Our Company</h3>
            </Fade>
          </Col>
          <Col className="left-col" span={24} md={12}>
            <Fade left>
            <img src="./about-img.jpg" alt="image of big house"/>
            </Fade>
          </Col>
          <Col className="right-col" span={24} md={12}>
            <Fade right>
            <div className="content">
              <p className="text">
                Access Capital Management Corp. is a full-service accounting company that concentrates specifically on customer needs. We offer accounting, payroll, tax preparation for businesses and individuals, tax planning, compilation and review, IRS representation, IRS tax resolutions and business advisory services for all business sizes. We are open, adaptable and personal. Our services are tailored to each individual or business needs. We make your business our business. We are committed to professionalism, honesty and customer satisfaction.
                <br/>
                <br/>
                Our company’s goal is to always ensure excellent services in a responsive, respectful and professional manner while upholding the norms and ethics of the American Institute of Certified Accountants (AICPA) and the Internal Revenue Service (IRS).
                <br/>
                <br/>
                With Access Capital Management Corp. smart accounting solutions and financial advice, your business will run smoothly and become more lucrative.
              </p>
            </div>
            </Fade>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SecondSection;
