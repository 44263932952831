import React, {useEffect, useState} from 'react';
import {Link, NavLink} from "react-router-dom";

const accounting = [
  "outsourcedAccounting",
  "outsourcedCfoServices",
  "corporationServices",
  "quickBooksAccounting",
  "payrollManagementService"
]

const taxServices = [
  "taxPlanning",
  "taxPreparationServices",
  "ircRepresentation"
]

const industries = [
  "hotelsAndRestaurants",
  "transportation",
  "retailAndEcommerce",
  "lawFirms",
  "startUps",
  "nonprofits"
]

const AccordionNav = ({isBurgerOpen, setIsBurgerOpen, linkNotWorking}) => {
  const [openedDropdown, setOpenedDropdown] = useState(null)

  const closeBurger = () => {
    setIsBurgerOpen(false)
  }

  useEffect(() => {
    if(!isBurgerOpen) {
      setOpenedDropdown(null)
    }
  }, [isBurgerOpen])

  return (
    <div className={`accordion-list ${isBurgerOpen ? "acc-open" : ""}`}>
      <ul className="myaccordion">
        <li className="myaccordion-item" onClick={closeBurger}>
          <NavLink className="" to="/">Home</NavLink>
        </li>
        <li
          className={`myaccordion-item my-acc ${openedDropdown === 1 ? "activeDrop w200" : ""}`}
          onClick={() => setOpenedDropdown(p => p === 1 ? null : 1)}
        >
          <Link to="" onClick={linkNotWorking}>
            Accounting Services
          </Link>
          <div className="dropdown">
            <ul className="list">
              {accounting.map(name => {
                return <li key={name} onClick={closeBurger}>
                  <Link to={`/${name}`}>{name}</Link>
                </li>
              })}
            </ul>
          </div>
        </li>
        <li
          className={`myaccordion-item my-acc ${openedDropdown === 2 ? "activeDrop w120" : ""}`}
          onClick={() => setOpenedDropdown(p => p === 2 ? null : 2)}
        >
          <Link className="navbar__link" to="" onClick={linkNotWorking}>Tax Services</Link>
          <div className="dropdown">
            <ul className="list">
              {taxServices.map(name => {
                return <li key={name} onClick={closeBurger}>
                  <Link to={`/${name}`}>{name}</Link>
                </li>
              })}
            </ul>
          </div>
        </li>
        <li
          className={`myaccordion-item my-acc ${openedDropdown === 3 ? "activeDrop w240" : ""}`}
          onClick={() => setOpenedDropdown(p => p === 3 ? null : 3)}
        >
          <Link className="navbar__link" to="" onClick={linkNotWorking}>Industries</Link>
          <div className="dropdown">
            <ul className="list">
              {industries.map(name => {
                return <li key={name} onClick={closeBurger}>
                  <Link to={`/${name}`}>{name}</Link>
                </li>
              })}
            </ul>
          </div>
        </li>
        <li className="myaccordion-item" onClick={closeBurger}>
          <NavLink className="navbar__link" to="/about">About Us</NavLink>
        </li>
        <li className="myaccordion-item" onClick={closeBurger}>
          <NavLink className="navbar__link" to="/contact">Contact Us</NavLink>
        </li>
      </ul>
    </div>
  );
};

export default AccordionNav;
