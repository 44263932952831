import React from 'react';
import "./ServicesSecondSection.scss";
import {Col, Row} from "antd";
import Fade from "react-reveal";

const ServicesSecondSection = ({data}) => {
  return (
    <div className="services_second_section">
      <div className="my-container">
        {data.map((v, i) => {
          const firstCol = v.list.slice(0, Math.ceil(v.list.length / 2))
          const secondCol = v.list.slice(Math.ceil(v.list.length / 2))

          return (<>
            <Row className="services_second_section_row" key={v.title}>
              <Col className="section-1" span={24} md={14}>
                  <Fade left cascade>
                <p className="title">{v.title}</p>
                <p className="subtitle">{v.text1}</p>
                <img className="aroundTextImg" src="/counter.jpg" alt=""/>
                <p className="subtitle">{v.text2}</p>
                  </Fade>
              </Col>
              <Col className="section-2" span={24} md={10}>
                  <Fade right cascade>
                      <img src={v.img} alt=""/>
                  </Fade>
              </Col>
              <Col className="title-col" span={24}>
                <p className="list_title fw-bold">{v.listTitle}</p>
              </Col>
              <Col className="ol-wrapper" span={24}>
                  <Fade bottom cascade>
                  <ol>
                  {firstCol.map((v, i) => <li key={i}>{v.listItem}</li>)}
                </ol>
                <ol>
                  {secondCol.map((v, i) => <li key={i}>{v.listItem}</li>)}
                </ol>
                  </Fade>
              </Col>
            </Row>

          </>)
        })}
      </div>
    </div>
  );
};

export default ServicesSecondSection;
