import React from 'react';
import "./About.scss";
import FirstSection from "./sections/FirstSection";
import SecondSection from "./sections/SecondSection";
import ThirdSection from "./sections/ThirdSection";

const About = () => {
  return (
    <div className="about_wrapper">
      <FirstSection/>
      <SecondSection/>

      <div className="my-container">
        <div className="about-divider"></div>
      </div>

      <ThirdSection/>
    </div>
  );
};

export default About;
