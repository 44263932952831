const data = [
    {
        name:"Tax Preparation Services",
        category:"Tax Services",
        title:"Tax Preparation Services",
        img:"/serviceImages/taxpreparation.jpg",
        text1:"Online tax applications would never be able to replace a local tax professional’s real-world experience. While doing things yourself using technology can seem to be less costly at first, it may end up costing you more in the long run due to mishandled deductions, miscalculations, and even IRS issues. At Access Capital Management Corp., our tax planning services are reliable, effective and customized. When we prepare your tax return, you will not have to wonder if it was done correctly. You should be assured that the tax returns were compiled in accordance with both current state and federal laws by a certified Mountain View, CA accountant.",
        text2:"We make it our business to know all the latest tax rules inside and out. We are still on the lookout for new laws that we can use to help our clients and their tax liabilities. We go through your paperwork, receipts, and recent tax returns step by step, applying tax techniques that will help you keep more of your earnings.",
        text3:"Small companies, entrepreneurs, and individuals will all benefit from our CPA firm’s tax planning services.",
        listTitle:"No matter what your needs require, you will benefit from our experience, expertise, and friendly customer service.",
        list:[
            {listItem:"Tax returns in Mountain View, CA"},
            {listItem:"Quicker tax refunds with E-file"},
            {listItem:"Tax returns for individuals"},
            {listItem:"Corporate, LLC, and other business tax planning"},
            {listItem:"Multi-state tax preparation for businesses"},
            {listItem:"Estate tax return preparation and filing"},
            {listItem:"Form 990 tax returns for nonprofits"},
            {listItem:"FBAR filing and expatriate tax planning"},
        ]
    }
]
export default data;
