const data = [
    {
        name:"Retail and E-commerce",
        category:"Industries",
        title:"Retail and E-commerce",
        img:"/serviceImages/retail.jpg",
        text1:"Retail and E-commerce is one of the most occurring and rapidly developing sectors along with the most dynamic market. The competition in this market requires wholesalers monitor the market changes to be regularly on profit. Nonetheless, it has a distinct effect on the quality or customer satisfaction fronts since it is difficult to do the same at the same level.",
        text2:"There are some specific challenges that retailers face on a daily basis, such as handling accounting, analyzing operational expenditure and sales, and ensuring their clients happy and satisfied. Consequently, when outsourcing their accounting and bookkeeping services to a professional service provider, they must concentrate on pricing strategy, customer satisfaction policy, and other key areas.",
        text3:"Access Capital Management Corp. offers proficient services in the areas of accounting, order processing, reconciliation and other.",
        listTitle:"Services offered for Retail and E-commerce industries",
        list:[
            {listItem:"Bookkeeping"},
            {listItem:"Accounts Payable"},
            {listItem:"Accounts Receivable"},
            {listItem:"Management Reporting"},
            {listItem:"Evaluation of internal controls"},
            {listItem:"Cash flow analysis"},
        ]
    }
]
export default data;
