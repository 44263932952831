import React from 'react';
import "./FirstSection.scss"
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem";
import {Breadcrumb} from "antd";
import {Link} from "react-router-dom";

const FirstSection = () => {
  return (
    <div className="hero">
      <div className="hero__bgi">
        <img src="./about-banner.jpg" alt=""/>
      </div>
      <div className="hero__content">
        <h1 className="hero__title">About Us</h1>
          <Breadcrumb className="breadcrumb">
              <BreadcrumbItem className="breadcrumb_items"><Link to="/">home</Link></BreadcrumbItem>
              <BreadcrumbItem className="breadcrumb_items">About Us</BreadcrumbItem>
          </Breadcrumb>
      </div>
    </div>
  );
};

export default FirstSection;
