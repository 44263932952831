const data = [
    {
        name:"Law Firms",
        category:"Industries",
        title:"Law Firms",
        img:"/serviceImages/lawfirms.jpg",
        text1:"Undoubtedly, accounting is a time-consuming process, and spending more time working with clients is preferred. By delegating your accounting and bookkeeping to Access Capital Management Corp. you will be able to focus on what you enjoy most about being a lawyer while growing profit for your firm. We will help your law firm streamline productivity and increase profitability by combining accounting best practices, technology, and innovative tax strategies.",
        text2:"Our company will happily manage the most time-consuming accounting and bookkeeping operations, such as processing routine accounting transactions, reconciling bank statements, and handling accounts payables and receivables. We will keep you updated every step of the way by organizing your financial data into meaningful reports that will enable you to monitor expenses and optimize cash flow. We will also develop a detailed plan to reduce your tax liabilities. We cover all of your tax preparation and planning needs, from preparing individual income tax returns for families to managing the most complicated corporate tax filings.",
        listTitle:"Services for Lawyers:",
        list:[
            {listItem:"Accounts payable and receivable"},
            {listItem:"Bank and credit card reconciliations"},
            {listItem:"Small business accounting"},
            {listItem:"Outsourced bookkeeping services"},
            {listItem:"Payroll services"},
            {listItem:"Cash flow analysis"},
            {listItem:"Business tax planning strategies"},
            {listItem:"Tax preparation for lawyers"},
            {listItem:"Financial statement preparation"},
        ]
    }
]
export default data;
