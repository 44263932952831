const data = [
    {
        name:"Payroll Management Service",
        category:"Accounting Services",
        title:"Outsourced Payroll Services",
        img:"/serviceImages/payroll.jpg",
        text1:"Are you still attempting to manage payroll on your own? Payroll processing in-house is complex, frustrating, and typically, is more expensive than outsourcing. Forget of estimating various forms of wages, complex payroll taxes, and W2s. Through outsourcing your payroll to Access Capital Management Corp., you will get a new start right away. In the Mountain View, CA area, we process payroll for a number of small businesses.",
        text2:"Our outsourced payroll services make sense for small businesses. Most business owners are not cut out for deciphering complicated payroll tax laws, and failures on your payroll taxes will result in large fines that compound before you even know you have made a mistake. These penalties will eat away at your earnings while still causing an undue stress. You will save on potential tax fines and gain a peace of mind by knowing that your payroll taxes are correct and collected on time as we manage your payroll.",
        text3:"At Access Capital Management Corp., our rates are competitive and our customer service is unmatched. We provide cost-effective payroll solutions that are backed up by friendly and attentive customer service. If you have an issue, please contact us by phone or email, and we will get back to you as soon as possible.",
        listTitle:"Payroll Provider",
        list:[
            {listItem:"Direct deposits"},
            {listItem:"Payroll tax filings"},
            {listItem:"Preparation of W-2’s, W-3’s, and 1099s"},
            {listItem:"Customized payroll reports"},
            {listItem:"Payroll processing on a weekly, biweekly, or monthly basis"},
            {listItem:"Weekly financial summaries"},
            {listItem:"Worker’s compensation audits"},
            {listItem:"Unemployment claims"},
        ]
    }
]
export default data;
