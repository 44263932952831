import "./App.scss";
import "./assets/scss/global.scss";
import "./assets/scss/variables.scss";
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/bundle";
import {Route, Routes} from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage";
import About from "./pages/About/About";
import Services from "./pages/Services/Services";
import Contact from "./pages/Contact/Contact";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BackTop} from "antd";
import {faAngleUp} from "@fortawesome/free-solid-svg-icons";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import {useEffect, useState} from "react";
import PageNotFound from "./pages/PageNotFound/PageNotFound";

function App() {
    const [isBurgerOpen, setIsBurgerOpen] = useState(false);

    useEffect(() => {
        if (isBurgerOpen) {
            document.body.className = "bodyOverflowH"
        } else {
            document.body.className = ""
        }
    }, [isBurgerOpen])

    return (
        <div className="App">
            <Header isBurgerOpen={isBurgerOpen} setIsBurgerOpen={setIsBurgerOpen}/>
            <Routes>
                <Route path="/" element={<MainPage/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/:services" element={<Services/>}/>
                <Route path="*" element={<PageNotFound/>} />
            </Routes>
            <BackTop><FontAwesomeIcon className="upIcon" icon={faAngleUp} fixedWidth/></BackTop>
            <Footer/>
        </div>
    );
}

export default App;
