import React from 'react';
import "./Contact.scss"
import FirstSection from "./sections/FirstSection";
import SecondSection from "./sections/SecondSection";
import ThirdSection from "./sections/ThirdSection";

const Contact = () => {
  return (
    <div>
      <FirstSection/>
      <SecondSection/>
      <ThirdSection/>
    </div>
  );
};

export default Contact;
